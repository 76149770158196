<template>
  <section class="static-wrap">
    <div class="content">
        <van-search
          v-model="nickname"
          placeholder="请输入昵称"
          @search="onSearch"
        />
      <div class="list">
        <div>用户昵称</div>
        <div>注册时间</div>
        <div>app版本</div>
      </div>
      <div class="pull-content">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="pageNum > 2 ? '我是有底线的～' : list.length > 0 ? '' : '暂无数据'"
                @load="onLoad"
            >
              <div class="list" v-for="(item, index) in list" :key="index">
                <div>{{item.nickname}}</div>
                <div>{{formatDate(item.registerTime)}}</div>
                <div>{{item.appVersion}}</div>
              </div>
            </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </section>
</template>

<script>
import { Toast } from "vant";
import { getPassRecord } from "@/api/coupon";
import { formatDate } from "@/filters/index";

export default {
    data() {
        return {
          nickname: '',
          list: [],
          pageNum: 1,
          requireFlag: true,
          loading: false,
          finished: false,
          refreshing: false,
          formatDate
        }
    },
  components: {
  },
  mounted(){
    this.$store.commit("loading/HIDELOADING");
  },
  methods: {
    onSearch(){
      this.list = [];
      this.pageNum = 1;
      this.onLoad()
    },
    onLoad() {
      debugger
      if(!this.requireFlag) return
      this.requireFlag = false
      getPassRecord({ 
        nickname: this.nickname,
        uuid: this.$route.query.ticketUuid,
        pageNum: this.pageNum,
        pageSize: 20
      }).then((res) => {
        if (res.success) {
          this.pageNum++
          if (this.refreshing) {
              this.list = [];
              this.pageNum = 1;
              this.refreshing = false;
          }
          this.list = this.list.concat(res.data.aaData || [])
          this.loading = false;
          if(res.data.iTotalDisplayRecords <= this.list.length){
            this.finished = true;
          }
          
        }else{
          this.loading = false;
          this.finished = true;
          Toast(res.msg);
        }
        this.requireFlag = true
      }).catch((err) => {
        this.requireFlag = true
      });
    },
    onRefresh() {
        // 清空列表数据
        this.finished = false;
        this.pageNum = 1;
        this.list = [];

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad()
    },
  }
}
</script>
<style>
  .van-search__content{
    border-radius: 6px;
  }
</style>
<style scoped lang="scss">
.static-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 236, 0, .8) 0%, rgba(255,255,255,0) 170px);
  .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 24px);
    margin: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(217,217,229,0.42);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .van-search {
    border-bottom: 0.5px solid rgba(0,0,0,0.1);
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin: 20px 0;
    >div {
      flex: 1;
      &:nth-child(1){
        padding: 0 8px;
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
      }
      &:nth-child(2) {
        flex: 1.7;
      }
    }
  }
  .pull-content {
    height: calc(100% - 120px);
    overflow-y:scroll;
    margin-top: -20px;
    .list {
      color: #666666;
      font-size: 13px;
      div:nth-child(1){
        padding: 0 8px;
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
      }
    }
  }
}

</style>

